<template>
    <div class="w-full md:w-1/2 mx-auto">
        <div class="flex flex-col">
            <div class="mx-auto w-full md:w-2/3">
                <form @submit.prevent="loadContractAbi" id="load-abi-form">
                    <text-input
                        text="Contract address"
                        v-model="contractAddress"
                        help="Insert the contract of the token you want to manage"
                        :error="errors.load || ''"
                    />
                    <main-button @click="loadContractAbi" v-if="!loadingForm">Load</main-button>
                </form>
            </div>
            <div class="mx-auto w-full mt-4 flex flex-col" v-if="!errors.load">
                <div class="flex flex-row">
                    <div
                        class="flex flex-grow justify-center p-3 border-l border-t border-b hover:bg-gray-300 cursor-pointer"
                        @click="activeTab = 'state'"
                        :class="{'bg-gray-200':activeTab==='state'}"
                    >State
                    </div>
                    <div class="flex flex-grow justify-center p-3 border hover:bg-gray-300 cursor-pointer"
                         @click="activeTab = 'functions'"
                         :class="{'bg-gray-200':activeTab==='functions'}"
                    >Functions
                    </div>
                    <div
                        class="flex flex-grow justify-center p-3 border-t border-r border-b hover:bg-gray-300 cursor-pointer"
                        @click="activeTab = 'events'"
                        :class="{'bg-gray-200':activeTab==='events'}"
                    >Events
                    </div>
                    <div
                        class="flex flex-grow justify-center p-3 border-t border-r border-b hover:bg-gray-300 cursor-pointer"
                        @click="activeTab = 'Easy Mode'"
                        :class="{'bg-gray-200':activeTab==='Easy Mode'}"
                    >Easy Mode
                    </div>
                </div>
                <div class="w-full">
                    <div v-for="area in states" v-if="activeTab === 'state'" :key="area.name">
                        <div class="p-3 border-l border-r border-b flex flex-col">
                            <div class="flex flex-row">
                                <span>{{ area.name }}</span>
                                <span class="italic text-gray-500 ml-3" v-for="output in area.outputs">
                                    {{ getTypeFormatted(output) }}
                                </span>
                            </div>
                            <div class="flex flex-col">
                                <div
                                    class="flex"
                                    v-if="output.value !== null && /^u?int/.test(output.type)"
                                    v-for="output in area.outputs">
                                    <div v-if="hasDecimals && output.hasDecimals">
                                        <code>{{ output.value / Math.pow(10, decimals) | numberFormat }}</code>
                                        <code class="italic text-gray-500 ml-3" v-if="output.value >= 1e6">
                                            {{ output.value / Math.pow(10, decimals) | short }}
                                        </code>
                                    </div>
                                    <div v-else>
                                        <code>{{ output.value | numberFormat }}</code>
                                        <code class="italic text-gray-500 ml-3" v-if="output.value >= 1e9">
                                            {{ output.value | short }}
                                        </code>
                                    </div>
                                </div>
                                <div
                                    class="flex"
                                    v-else
                                    v-for="output in area.outputs">
                                    <div>
                                        <code>{{ output.value }}</code>
                                    </div>
                                </div>
                                <div class="flex" v-if="!area.inputs.length">
                                    <main-button
                                        :disabled="area.loading"
                                        :loading="area.loading"
                                        @click="runNormalQuery(area)">Query
                                    </main-button>
                                </div>
                            </div>
                            <div class="my-3 flex flex-col" v-if="area.inputs.length">
                                <div v-for="input in area.inputs">
                                    <text-input
                                        v-model="input.value"
                                        :help="input.name"
                                        :text="input.type"
                                        required
                                    ></text-input>
                                </div>
                                <div class="flex flex-shrink">
                                    <main-button @click="runNormalQuery(area)">Query</main-button>
                                </div>
                            </div>
                            <span class="text-sm text-blue-500 underline cursor-pointer block"
                                  @click="area.interfaceOpen = !area.interfaceOpen">
                                See interface
                                <span v-if="area.interfaceOpen">&triangle;</span>
                                <span v-if="!area.interfaceOpen">&triangledown;</span>
                            </span>
                            <div v-if="!area.interfaceOpen">
                                <pre>{{ area.original }}</pre>
                            </div>
                        </div>
                    </div>
                    <div v-if="activeTab === 'Easy Mode'">
                        <div class="p-3 text-2xl">Easy Mode</div>
                        <div class="table block">
                            <div class="table-row">
                                <div class="table-cell">
                                    Token name: {{ erc20.name }}
                                </div>
                            </div>
                            <div class="table-row">
                                <div class="table-cell">
                                    Total Supply: {{ erc20.totalSupply / Math.pow(10, erc20.decimals) | numberFormat }}
                                </div>
                            </div>
                            <div class="table-row">
                                <div class="table-cell">
                                    Decimals: {{ erc20.decimals }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TextInput from "../components/generator/TextInput";
import MainButton from "../components/MainButton";
import Web3 from 'web3';
import {ChainId, Fetcher, Token} from '@uniswap/sdk'

const web3 = new Web3(window.ethereum)

export default {
    name: "TokenManager",
    components: {MainButton, TextInput},
    data() {
        return {
            contractAddress: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
            loadingForm: false,
            contract: null,
            hasDecimals: false,
            decimals: null,
            errors: {
                load: ""
            },
            abi: [],
            activeTab: "Easy Mode",
            erc20: {
                name: null,
                decimals: null,
                totalSupply: null,
                burnMethod: null,
                mintMethod: null
            }
        }
    },
    computed: {
        events: {
            get() {
                if (!this.abi) return []
                return this.abi.filter(m => {
                    return ['view', 'pure'].includes(m.stateMutability)
                })
            }
        },
        functions: {
            get() {
                if (!this.abi) return []
                return this.abi.filter(m => {
                    if (m.type !== 'function') return false
                    return ['payable', 'nonpayable'].includes(m.stateMutability)
                })
            }
        },
        states: {
            get() {
                if (!this.abi) return []
                return this.abi.filter(m => {
                    return ['view', 'pure'].includes(m.stateMutability)
                })
            }
        }
    },
    async mounted() {
        const test = await Fetcher.fetchTokenData(56, '0xbF30A1DAb8a2fa14B852a87914C377048cef18eB')
        this.loadContractAbi()

    },
    methods: {
        ensureContract: res => {
            if (res.ok)
                return res.json()
            throw Error("Contract does not exist, or is not verified.")
        },
        async loadContractAbi() {
            this.loadingForm = true
            this.errors.load = false
            this.abi = []
            this.contract = null;
            let input = document.querySelector('#load-abi-form input')
            input.blur()
            await fetch(`http://localhost:3000/${this.contractAddress}/abi`)
                .then(this.ensureContract)
                .then(res => {
                    this.contract = new web3.eth.Contract(res, this.contractAddress)
                    this.hasMethod("decimals()")
                        .then(r => this.hasDecimals = r)
                        .then(() => {
                            this.runQuery(this.getMethod("decimals"))
                                .then(d => this.decimals = parseInt(d))
                        })
                    this.abi = this.applyOutputPrototypes(res)
                    this.getTokenName()
                    this.states.forEach(state => {
                        if (state.inputs.length) return;
                        this.runNormalQuery(state)
                    })
                    this.setERC20Data()
                })
                .catch(e => {
                    this.errors.load = e
                    input.focus()
                })
                .finally(() => this.loadingForm = false)
        },
        getTypeFormatted(output) {
            if (output.internalType === output.type) return output.type
            return `${output.internalType} (${output.type})`
        },
        applyOutputPrototypes(abi) {
            return abi.map(thing => {
                thing.original = {...thing}
                thing.interfaceOpen = false
                thing.loading = false
                if (thing.inputs)
                    thing.inputs = thing.inputs.map(input => {
                        return {
                            ...input,
                            value: null,
                            hasDecimals: this.guessIfNeedsDecimals(thing)
                        }
                    })
                if (thing.outputs)
                    thing.outputs = thing.outputs.map(output => {
                        return {
                            ...output,
                            value: null,
                            hasDecimals: this.guessIfNeedsDecimals(thing)
                        }
                    })
                return thing
            })
        },
        guessIfNeedsDecimals(method) {
            let mightNeed = [
                "_maxTxAmount",
                "allowance",
                "balanceOf",
                "reflectionFromToken",
                "tokenFromReflection",
                "totalFees",
                "totalSupply",
            ]
            return mightNeed.includes(method.name)
        },
        async runNormalQuery(method) {
            method.loading = true
            let args = []
            for (let input of method.inputs) {
                args.push(input.value)
            }
            if (args.length) {
                method.outputs[0].value = await this.contract.methods[method.name](...args).call()
            } else {
                method.outputs[0].value = await this.contract.methods[method.name]().call()
            }
            method.loading = false
        },
        async runQuery(method, args = []) {
            if (!method) return
            return this.contract.methods[method.name](...args).call()
        },
        async runMethod(methodName, args = []) {
            return this.contract.methods[methodName](...args).call()
        },
        async hasMethod(signature) {
            const code = await web3.eth.getCode(this.contractAddress);
            const hash = web3.eth.abi.encodeFunctionSignature(signature);
            return code.indexOf(hash.slice(2, hash.length)) > 0;
        },
        getMethod(methodName) {
            return this.abi.find(a => a.name === methodName)
        },
        async getTokenName() {
            if (!this.abi.length) return null
            if (await this.hasMethod('name()'))
                console.log(await this.runMethod('name'))
        },
        async setERC20Data() {
            this.erc20.name = await this.runMethod('name')
            this.erc20.decimals = await this.runMethod('decimals')
            this.erc20.totalSupply = await this.runMethod('totalSupply')
        }
    }
}
</script>

<style scoped>
</style>
